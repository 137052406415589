<template>
  <div>
    <h3>Jack Trice View</h3>
    <hr>
    <div v-if="!loading">
      <div v-for="challenge in challenges" :key="challenge.id">
        <h6 style="vertical-align: middle;"><img class="logo" v-bind:src="'/server/logos/'+challenge.away.logo" height="20" /> {{challenge.away.name}}  @  <img class="logo" v-bind:src="'/server/logos/'+challenge.home.logo" height="20" /> {{challenge.home.name}}</h6>
        <p>Kickoff at {{ challenge.eventTime | moment("h:mm a") }}</p>
        <p>{{challenge.line.name}} / -{{challenge.spread}} / {{challenge.totalpoints}}</p>
        
        
        <table class="table table-dark table-sm">
          <thead>
            <tr>
              <th scope="col">Player</th>
              <th scope="col">Winner</th>
              <th scope="col">Spread</th>
              <th scope="col">O/U</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="bet in challenge.bets" :key="bet.id">
              <template v-if="challenge.results === null">
                <td>{{bet.gambler.user.firstName}} {{bet.gambler.user.lastName.charAt(0)}}.</td>
                <td>{{bet.winner.name}}</td>
                <td>{{bet.spread.name}}</td>
                <td>{{bet.totalpoints}}</td>
              </template>
              <template v-else>
                <td>{{bet.gambler.user.firstName}} {{bet.gambler.user.lastName.charAt(0)}}.</td>
                <td
                  :class="challenge.results.winner.name === bet.winner.name ? 'table-success' : 'none'"
                >{{bet.winner.name}}</td>
                <td
                  :class="challenge.results.spread.name === bet.spread.name ? 'table-success' : 'none'"
                >{{bet.spread.name}}</td>
                <td
                  :class="challenge.results.totalpoints === bet.totalpoints ? 'table-success' : 'none'"
                >{{bet.totalpoints}}</td>
              </template>
            </tr>
          </tbody>
        </table>
        <hr>
      </div>
    </div>
    <div v-else>
      Loading
    </div>
    <hr />
  </div>
</template>

<script>
import { api } from "@/utils/api";

export default {
  name: "Challenge",
  components: {
  },
  data() {
    return {
      challenges: null,
      loading: true
    };
  },
  created() {
    let self = this;
    api(
      `query {
        jacktrice { id
          home {
            logo
            name
          }
          away {
            logo
            name
          }
          line {
            name
          }
          spread
          totalpoints
          eventDate
          eventTime
          bets {
            id
            gambler {
              user {
                firstName
                lastName
              }
            }
            winner {
              name
            }
            spread {
              name
            }
            totalpoints
          }
          results {
            winner {
              name
            }
            spread {
              name
            }
            totalpoints
          }
        }
      }
      `
    ).then((data) => {
      self.challenges = data.jacktrice;
      self.loading = false;
    });
  }
};
</script>

<style scoped>
.table-success {
  color: #00bc8c;
  background-color: transparent;
}
td
{
 max-width: 50px;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}
.logo {
  padding-right: 3px;
}
</style>